import React, { useRef } from 'react';

function LoginForm({ err, onSubmit }) {
    const userRef = useRef(null);
    const passRef = useRef(null);
    const formRef = useRef(null);

    function submitForm(event) {
        event.preventDefault();
        if (formRef.current.checkValidity()) {
            onSubmit(userRef.current.value, passRef.current.value);
        }
        return;
    }
    
    return (
        <div className="container text-white h-100">
            <div className="d-flex justify-content-center align-items-center h-100">
                <form ref={formRef} onSubmit={submitForm} autoComplete="off">
                    <div className="form-group">
                        <label htmlFor="inputEmail">Username</label>
                        <input required ref={userRef} type="text" className="form-control" id="inputEmail" placeholder="Enter username" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="inputPassword">Password</label>
                        <input required ref={passRef} type="password" className="form-control" id="inputPassword" placeholder="Password" />
                    </div>
                    <button className="btn btn-light w-100">Submit</button>
                    {err ?
                        <div className="alert alert-danger mt-2" role="alert">
                            No Dice. Try Again.
                        </div>
                    : null}
                </form>
            </div>
        </div>
    )
}

export default LoginForm;
