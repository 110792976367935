import React, { Component } from 'react';

class FocusBox extends Component {
    componentDidMount() {
        this.nameInput.focus();
    }
    render() {
        return <input className={this.props.className} type='text' ref={(input) => { this.nameInput = input; }} value={this.props.field} onChange={this.props.onChange} ></input>
    }
}

export default FocusBox;