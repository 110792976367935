import React, { Component } from 'react';
import FocusBox from './FocusBox';
import { Shuffle, ArrowLeft, ArrowRight, Tools } from 'react-bootstrap-icons';

class Header extends Component {
    constructor(props) {
        super ( props );
        this.state = {
            search: ''
        };
        this.update = this.update.bind(this);
        this.submit = this.submit.bind(this);
    }

    update(e) {
        this.setState({search: e.target.value})
    }

    submit(e) {
        e.preventDefault();
        let set = new Set();
        let tags = this.state.search.split(' ');
        for ( let i = 0; i < tags.length; i++ ) {
            if ( tags[i].length > 0 ) {
                set.add(tags[i]);
            }
        }
        this.props.onSubmit([...set]);
    }

    render() {
        let inside = undefined;
        if ( this.props.isdupe !== true ) {
            let name = (this.props.fast === undefined) ? "Fast" : "Slow";
            inside = 
                [(<form key="form" className="order-md-3 col-md-7 col-12" onSubmit={this.submit} >
                    <FocusBox className="form-control" field={this.state.search} onChange={this.update} />
                </form>),
                (<div key="fast-tag" className="px-2 col-md-1"><button type="button" onClick={this.props.toggleFast} className="btn btn-light mx-auto w-100" id="exampleCheck1">{name}</button></div>),
                (<div key="shuffle" className="order-md-4 col-3 col-md-1">
                    <button onClick={this.props.onRand} className="w-100 btn btn-outline-light">
                        <Shuffle />
                    </button>
                </div>)]
        }
        return ( 
            <div className="row headrow d-flex flex-md-row flex-grow-1 flex-justify-between">
                {inside}
                <div className="order-md-1 col-3 col-md-1">
                    <button onClick={this.props.prev} className="w-100 btn btn-outline-light">
                        <ArrowLeft />
                    </button>
                </div>
                <div className="order-md-2 col-3 col-md-1">
                    <button onClick={this.props.next} className="w-100 btn btn-outline-light">
                        <ArrowRight />
                    </button>
                </div>
                <div className="order-md-5 col-3 col-md-1">
                    <button onClick={this.props.dupe} className="w-100 btn btn-outline-light">
                        <Tools />
                    </button>
                </div>
            </div>       
        )
    }   
}

export default Header;
