import React, { Component } from 'react';
import Textbox from "./AddBox";
import TagBuilder from "./NewTagBuilder";

class Taglist extends Component {
    render() {
        let tags = [];
        let builder = undefined;
        if ( this.props.tags.indexOf ( "untagged" ) >= 0 && this.props.tags.indexOf("discard") < 0 && this.props.tags.indexOf("fixme") < 0 ) {
            builder = (<TagBuilder tags={this.props.tags} fast={this.props.fast} id={this.props.id} add={this.props.updateTags}/>);
        } else if ( this.props.fast !== undefined ) {
            this.props.fast();
        }
        this.props.tags.sort().forEach(tag=>{
            tags.push ( <li key={tag}>{tag}</li>)
        });
        return (
            <div className="d-flex flex-column h-100">
                {builder}
                <ul className="mb-0 pl-0">{tags}</ul>
                <Textbox add={this.props.updateTags}/>
            </div>
        )
    }
}

export default Taglist;