import React, { Component } from 'react';
let map = require ( "../utils/extensionToTypeMap.json")
let _origin = undefined;

class ImageBox extends Component {

    shouldComponentUpdate(nextProps) {
        return nextProps.img !== this.props.img;
    }
    render() {
        if ( ! this.props.img ) {
            return (
                <div className={this.props.className}></div>
            )
        }
        let img = undefined;
        if ( !_origin ) {
            _origin = window.location.origin;
        }
        let src = `${_origin}/${this.props.img}`;
        let ext = this.props.img.substring(this.props.img.lastIndexOf('.')+1);
        let type = map[ext];
        if ( type === 'video' ) {
            if ( this.props.isMuted ) {
                img = <video autoPlay loop className="fullimage" src={src} controls muted />
            } else {
                img = <video autoPlay loop className="fullimage" src={src} controls />
            }
        } else if ( type === 'image' ) {
            img = <img alt="main_image" className="fullimage" src={src} />
        } else if ( type === 'flash' ) {
            img = <embed className="fullimage" src={src} />
        }

        return (
            <div className={this.props.className}>
                {img}
            </div>
        )     
    }   
}

export default ImageBox;
