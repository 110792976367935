import React, { Component } from 'react';
import FocusBox from './FocusBox';

class AddBox extends Component {
    constructor(props) {
        super ( props );
        this.state = {
            editing: false,
            field: ""
        }
        this.onChange = this.onChange.bind(this);
        this.submit = this.submit.bind(this);
    }

    onChange ( e ) {
        this.setState ( { field: e.target.value } );
    }

    submit (e) { 
        e.preventDefault();
        this.props.add ( this.state.field );
        this.setState({field: '', editing: false});
    }

    cancel (e) {
        e.preventDefault();
        if ( this.state.editing === false ) {
            this.setState({field: '', editing: false});
        }
    }

    render() {
        if ( this.state.editing ) {
            return <form onSubmit={this.submit}><FocusBox field={this.state.field} onChange={this.onChange} /></form>
        } else {
            return <div className="clickable px-2 d-inline-block" onClick={()=>{this.setState({editing: true})}}>+</div>
        }
    }
}

export default AddBox;