import React, { Component } from 'react';
import {tagMapGet, tagMapUpdate} from '../utils/localAPI';
const tagInfo = require("../utils/tag");
const tagMap = tagInfo.tagMap;

class NewTagBuilder extends Component {
    constructor(props) {
        super ( props );
        this.state = {
            queue: [],
            current: undefined,
        }
        this.onClick = this.onClick.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);

        tagMapGet(props.id).then(_queue=>{
            let queue = [...tagInfo.order];
            if ( _queue ) {
                queue = _queue.queue.reverse();
            }
            queue = queue.map(el=>{
                if ( el.indexOf('//') >= 0 ) {
                    let [cat,subcat] = el.split('//');
                    return { name: el, values: tagMap[cat][subcat], ghost: true, multiple: true };
                }
                return {name: el, ...tagMap[el]};
            });
            let current = queue[0];
            this.setState({queue,current},this.afterUpdate);
        });
    }
    componentDidMount() {
        //document.addEventListener('keydown', this.handleKeyPress);
    }
    handleKeyPress(event) {
        if ( document.activeElement.nodeName === 'INPUT' ) {
            return;
        }
        if (event.keyCode !== 37 && event.keyCode !== 39 && this.state.current ) {
            if ( event.keyCode > 64 && event.keyCode <= 90 ) {
                let key = String.fromCharCode(event.keyCode+32);
                console.log(key);
            } else if (event.keyCode === 13) {
                document.getElementById('tb_submit').click();
            }
        }
    }
    componentDidUpdate(prevprops) {
        if ( prevprops.id !== this.props.id ) {
            if ( document.getElementById('tagbuildform') ) {
                document.getElementById('tagbuildform').reset();
            }
            tagMapGet(this.props.id).then(_queue=>{
                let queue = [...tagInfo.order];
                if ( _queue ) {
                    queue = _queue.queue.reverse();
                }
                queue = queue.map(el=>{
                    if ( el.indexOf('//') >= 0 ) {
                        let [cat,subcat] = el.split('//');
                        return { name: el, values: tagMap[cat][subcat], ghost: true, multiple: true };
                    }
                    return {name: el, ...tagMap[el]};
                });
                let current = queue[0];
                this.setState({queue,current},this.afterUpdate);
            });
        }
    }

    afterUpdate() {
        /* This is a means of determining whether we need to press a button or not */
        if ( this.state.current && this.state.current.multiple !== true ) {
            this.state.current.values.forEach(val=>{
                if (this.props.tags.indexOf(val) >= 0 ) {
                    let t = document.getElementById(`tgmp-${val}`);
                    if ( t ) {
                        t.click();
                        document.getElementById('tb_submit').click();
                    }
                }
            })
        }
    }

    onClick(e) {
        e.preventDefault();
        // Get a list of all selected elements
        let selected = [];
        Array.prototype.slice.call(document.getElementsByClassName("check")).forEach(element=>{
            if (element.checked) {
                selected.push(element.value);
            }
        });
        // Ensure that the user has selected elements if they needed to
        if ( selected.length === 0 && this.state.current.multiple !== true && this.state.current.notNecessary !== true ) {
            return;
        }
        let queue = this.state.queue;
        let current = queue.shift();
        // Do we need to add any subcategories to the queue?
        if ( current.subcategories ) {
            current.subcategories.forEach(el=>{
                queue.unshift({name: `${current.name}//${el}`, values: tagMap[current.name][el], multiple: true, ghost: true});
            });
        }
        // Compute the tags that are going to be added, as well as whether any other additions are being made to the queue
        let ntags = [];
        selected.forEach(el=>{
            if ( tagMap[el] ) {
                if ( tagMap[el].values ) {
                    queue.unshift({ name: el, ...tagMap[el]});
                } else {
                    tagMap[el].subcategories.forEach(sel=>{
                        queue.unshift({name: `${el}//${sel}`, values: tagMap[el][sel], multiple: true, ghost: true});
                    });
                }
                if ( tagMap[el].ghost !== true ) {
                    ntags.push(el);
                }
            } else {
                ntags.push(el);
            }
        });
        // If its the end, pop fresh and untagged
        if ( queue.length === 0 ) {
            ntags.push('-fresh');
            ntags.push('-untagged');
        }
        ntags = ntags.join(" ");
        let queueToPost = queue.reduce((a,el)=>{return [...a,el.name]},[]).reverse();
        this.props.add(ntags).then(()=>{
            tagMapUpdate(this.props.id,queueToPost).then(()=>{
                if ( this.props.fast !== undefined ) {
                    // Avoid double counting (since the Taglist will also skip fully tagged tags)
                    if ( queue.length ) {
                        this.props.fast();
                    }
                } else {
                    this.setState({current:queue[0],queue},this.afterUpdate);
                }
            })
        });
    }
    
    render() {
        let items = [];
        console.log(this.state);
        if ( this.state.current === undefined ) {
            return null;
        }
        this.state.current.values.sort().forEach(element => {
            items.push((
                <div key={element} className="form-check">
                    <input className="check form-check-input" type={this.state.current.multiple === true ? "checkbox" : "radio"} name={this.state.current.multiple === true ? element : 'radio'} id={`tgmp-${element}`} value={element} />
                    <label className="form-check-label user-select-none" htmlFor={`tgmp-${element}`}>
                        {element}
                    </label>
                </div>
            ));
        });
        return (
            <form id="tagbuildform" className="mb-2 text-left overflow-auto" onSubmit={this.onClick}>
                <button type="submit" id="tb_submit" key="submit" className="btn btn-light mb-2">{this.state.queue.length === 1 ? "Post" : "Next"}</button>
                {items}
                <button type="button" key="discard" onClick={()=>this.props.add("discard -fresh")}className="btn btn-light mb-2 float-right">Discard</button>
                <button type="button" key="fixme" onClick={()=>this.props.add("fixme -fresh")}className="btn btn-light mb-2 mr-1 float-right">Fix Me</button>
            </form>
        )
    }
}

export default NewTagBuilder;