import Axios from 'axios'

const localInstance = Axios.create({
  withCredentials: true, validateStatus: false});
let _origin = undefined;

localInstance.interceptors.request.use(config => {
	if ( !_origin ) {
        _origin = window.location.origin; //.substring(0,window.location.origin.lastIndexOf(":")) + ":5000";
    }
	config.url = `${_origin}/api/${config.url}`;
	return config;
});

export const checkAuth = async () => {
    let res = await localInstance({ method: 'GET', url: `checkAuth` });
    if (res.status > 299) {
        throw res;
    }
    return true;
}

export const login = async (username, password) => {
    let res = await localInstance({method: 'POST', url: `login`, data: { username, password } });
    if (res.status > 299) {
        throw res;
    }
    return res.data;
};

export const getImg = async (id) => {
  try {
    let res = await localInstance({method: 'GET', url: `v1/files/${id}`});
    if ( res.status > 299 ) {
      throw res;
    }
    return res.data;
  } catch ( e ) {
    console.error ( e );
    return undefined;
  }
};

export const searchTags = async (tags, any=false) => {
  try {
    let res = await localInstance({method: 'POST', url: `v1/files/search`, data: {tags, any}});
    if ( res.status > 299 ) {
      throw res;
    }
    return res.data;
  } catch ( e ) {
    console.error ( e );
    return [];
  }
};

export const getDupes = async () => {
  try {
    let res = await localInstance({method: 'GET', url: `v1/dupes/`});
    if ( res.status > 299 ) {
      throw res;
    }
    return res.data;
  } catch ( e ) {
    console.error ( e );
    return [];
  }
}

export const getAll = async () => {
  try {
    let res = await localInstance({method: 'GET', url: `v1/files/`});
    if ( res.status > 299 ) {
      throw res;
    }
    return res.data;
  } catch ( e ) {
    console.error ( e );
    return [];
  }
}

export const getBySrc = async ( src ) => {
  try {
    let res = await localInstance({method: 'POST', 'url': `v1/files/src/`, data: {src}});
    if ( res.status > 299 ) {
      throw res;
    }
    return res.data;
  } catch ( e ) {
    return undefined;
  }
}

export const upTags = async (id, tags) => {
  try {
    let res = await localInstance({method: 'PUT', 'url': `v1/files/${id}`, data: {tags}});
    if ( res.status > 299 ) {
      throw res;
    }
    return res.data;
  } catch ( e ) {
    console.error ( e );
    return undefined;
  }
}

export const fixOriginal = async ( src, type ) => {
  try {
    let res = await localInstance({method: 'POST', url: 'v1/files/fix/', data: { src, type }});
    if ( res.status > 299 ) {
      throw res.data;
    }
    return res.data;
  } catch ( e ) {
    console.error ( e );
    return undefined;
  }
}

/**
 * Replaces the old file with the newfile
 * @param string id The id of the old file
 */
export const dupeReplace = async ( id ) => {
  try {
    let res = await localInstance({method: 'POST', url: 'v1/dupes/repl/', data: {id}});
    if ( res.status > 299 ) {
      throw res.data;
    }
  } catch ( e ) {
    console.error ( e );
    return undefined;
  }
}

export const deleteAndTag = async (id, tags, original, duplicate) => {
  try {
    let res = await localInstance({method: 'POST', url: 'v1/dupes/dTag/', data: {id, tags, original, duplicate}});
    if ( res.status > 299 ) {
      throw res.data;
    }
    return res.data;
  } catch (e ) {
    console.error ( e );
    return undefined;
  }
}

export const renameAndAdd = async (original) => {
  try {
    let res = await localInstance({method: 'POST', url: 'v1/dupes/rAdd/', data: {original}});
    if ( res.status > 299 ) {
      throw res.data;
    }
    return res.data;
  } catch ( e ) {
    console.error ( e );
    return undefined;
  }
}

/**
 * Updates (or adds) an entry in the tagmap storage
 * @param {string} id A string identifying the object this queue is for
 * @param {string[]} queue An array of the queue left in the tagmap
 */
export const tagMapUpdate = async (id, queue) => {
  try {
    let res = await localInstance({method: 'POST', url: `v1/tagmap/${id}`, data: {queue}});
    if ( res.status > 299 ) {
      throw res.data;
    }
    return res.data;
  } catch ( e ) {
    console.error ( e );
    return undefined;
  }
}

/**
 * Lookups the current queue for the object
 * @param {string} id The String identifying the object
 */
export const tagMapGet = async ( id ) => {
  try {
    let res = await localInstance({method: 'GET', url: `v1/tagmap/${id}`});
    if ( res.data === "" ) res.data = undefined;
    if ( res.status > 299 ) {
      throw res.data;
    }
    return res.data;
  } catch ( e ) {
    console.error ( e );
    return undefined;
  }
}

export const tagUpdate = async (id, tags) => {
  try {
    let res = await localInstance({method: 'POST', url: `v1/files/addtags/${id}`, data:{tags}});
    if ( res.status > 299 ) {
      throw res.data;
    }
    return res.data;
  } catch ( e ) {
    console.error ( e );
    return undefined;
  }
}
