import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import AuthContainer from './components/AuthContainer';

ReactDOM.render(
    <AuthContainer>
        <App />
    </AuthContainer>, document.getElementById('root'));
registerServiceWorker();
