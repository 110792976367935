import React, { Component } from 'react';
import ImageBox from "./ImageBox";
let map = require ( "../utils/extensionToTypeMap.json")

class DupeContainer extends Component {
    render() {
        if ( this.props.image === undefined ) {
            return <div className="container-fluid row my-3 fill"></div>
        }
        let bg = undefined;
        let divs = undefined;
        if ( this.props.oimg ) {
            bg = "success";
            divs = [(<button key="radd" onClick={this.props.rAdd} className="mx-3 clickable btn">Rename and Add</button>),
                    (<div key="tag" className="mx-3 mt-2">{this.props.tag}</div>),
                    (<button key="discard" onClick={this.props.dTag} className="mx-3 clickable btn">Discard but keep tag</button>),
                    (<button key="repl" onClick={this.props.repl} className="mx-3 clickable btn">Replace</button>)]
        } else {
            bg = "failure";
            divs = [(<button key="fix" onClick={()=>{this.props.fixO( {src: `/${this.props.dupe}`, type: map[this.props.dupe.substring(this.props.dupe.lastIndexOf('.')+1)]})}} className="mx-3 clickable btn">Fix Original</button>)];
        }
        return ( 
            <div className="py-3 h-100">
                <div className="container-fluid d-flex justify-content-center mh-15 mb-1">
                    {divs}
                </div>
                <div className="container-fluid row d-flex h-100 mh-100">
                    <ImageBox img={this.props.dupe} className={`col-6 no-float mh-100 ${bg}`} />
                    <ImageBox img={this.props.image} isMuted={true} className="col-6 no-float mh-100" />
                </div>
            </div>
        )
    }   
}

export default DupeContainer;
