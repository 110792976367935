import React, { Component } from 'react';
let _origin = undefined;
class Main extends Component {

    shouldComponentUpdate(nextProps) {
        return nextProps.img.src !== this.props.img.src;
    }
    render() {
        if ( ! this.props.img ) {
            return (
                <div className="col-10 no-float"></div>
            )
        }
        let img = undefined;
        if ( !_origin ) {
            _origin = window.location.origin;
        }
        let src = `${_origin}${this.props.img.src}`;
        console.log(this.props.img);
        if ( this.props.img.type === 'video' ) {
            img = <video autoPlay loop className="fullimage" src={src} controls />
        } else if ( this.props.img.type === 'image' ) {
            img = <img alt={this.props.img.tags} className="fullimage" src={src} />
        } else if ( this.props.img.type === 'flash' ) {
            img = <embed className="fullimage" src={src} />
        }


        return (
            <div className="col-md-10 order-1 order-md-2 no-float mh-100">
                {img}
            </div>
        )     
    }   
}

export default Main;
