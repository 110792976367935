import React, { Component } from 'react';
import Sidebar from "./Sidebar";
import Main from "./Main";

class ImageContainer extends Component {
    render() {
        if ( this.props.image === undefined ) {
            return <div className="container-fluid row my-3 fill"></div>
        }
        return ( 
            <div id={this.props.image._id} className="container-fluid row py-3 d-flex" style={{ height: '94%' }}>
                <Sidebar fast={this.props.fast} id={this.props.image._id} tags={this.props.image.tags} updateTags={this.props.updateTags} />
                <Main img={this.props.image} />
            </div>
        )
    }   
}

export default ImageContainer;