import React, { Component } from 'react';
import './App.css';
import Header from "./components/Header";
import ImageContainer from "./components/ImageContainer";
import DupeContainer from "./components/DupeContainer";
import { getImg, searchTags, upTags, getAll, getDupes, getBySrc, fixOriginal, deleteAndTag, renameAndAdd, dupeReplace } from "./utils/localAPI";
import assert from 'assert';

function shuffle(array) {
  var currentIndex = array.length, temporaryValue, randomIndex;
  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }
  return array;
}

function tagSetToString(set) {
  let arr = [...set].sort();
  let string = "";
  arr.forEach((val)=>{
    string += val
  });
  return string;
}

class App extends Component {
  constructor ( props ) {
    super ( props );
    this.state = {
      searchQuery: undefined,
      imgList: [],
      cI: 0,
      cImg: undefined,
      dupe: false,
      oimg: undefined,
      fast: undefined
    }
    this.onSubmit   = this.onSubmit.bind(this);
    this.nextImg    = this.nextImg.bind(this);
    this.prevImg    = this.prevImg.bind(this);
    this.dupe       = this.dupe.bind(this);
    this.fixO       = this.fixO.bind(this);
    this.dTag       = this.dTag.bind(this);
    this.repl       = this.repl.bind(this);
    this.rAdd       = this.rAdd.bind(this);
    this.toggleFast = this.toggleFast.bind(this);
    this.updateTags = this.updateTags.bind(this);
  }
  async toggleFast() {
    let new_fast = undefined;
    if ( this.state.fast === undefined ) {
      new_fast = this.nextImg;
      if ( this.state.searchQuery === undefined ) {
        let string = "";
        let list = await getAll();
        this.newList(list, string);
      }
    }
    this.setState({fast: new_fast});
  }
  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyPress(this));
  }
  handleKeyPress(_this) {
    return (event) => {
      if ( document.activeElement.nodeName === 'INPUT' ) {
        return;
      }
      if (event.keyCode === 37) {
        //LEFT
        _this.prevImg();
      } else if (event.keyCode === 39) {
        //RIGHT
        _this.nextImg();
      } else if (event.keyCode === 46) {
        //DELETE
        console.log('delete');
        this.updateTags("discard -fresh")
      }
    }
  }
  async repl () {
    if ( this.state.oimg ) {
      let { _id } = this.state.cImg;
      await dupeReplace(_id);
      this.state.imgList.splice(this.state.cI,1);
      let cI = this.state.cI;
      if ( cI < 0 ) {
        cI = this.state.imgList.length - 1;
      } else if ( cI >= this.state.imgList.length ) {
        cI = 0;
      }
      let cImg = this.state.imgList[cI];
      let oimg = undefined;
      if ( cImg ) {
        oimg = await getBySrc(`/${cImg._id}`);
      }
      this.setState({cImg,oimg,cI});
    }
  }
  async dTag () {
    if ( this.state.oimg ) {
      let { duplicate, tags, _id } = this.state.cImg;
      let id = this.state.oimg._id;
      await deleteAndTag ( id, tags, _id, duplicate );
      this.state.imgList.splice(this.state.cI,1);
      let cI = this.state.cI;
      if ( cI < 0 ) {
        cI = this.state.imgList.length - 1;
      } else if ( cI >= this.state.imgList.length ) {
        cI = 0;
      }
      let cImg = this.state.imgList[cI];
      let oimg = undefined;
      if ( cImg ) {
        oimg = await getBySrc(`/${cImg._id}`);
      }
      this.setState({cImg,oimg,cI});
    }
  }
  async rAdd () {
    if ( this.state.oimg ) {
      let { _id } = this.state.cImg;
      await renameAndAdd(_id);
      this.state.imgList.splice(this.state.cI,1);
      let cI = this.state.cI;
      if ( cI < 0 ) {
        cI = this.state.imgList.length - 1;
      } else if ( cI >= this.state.imgList.length ) {
        cI = 0;
      }
      let cImg = this.state.imgList[cI];
      let oimg = undefined;
      if ( cImg ) {
        oimg = await getBySrc(`/${cImg._id}`);
      }
      this.setState({cImg,oimg,cI});
    }
  }
  async dupe () {
    if ( ! this.state.dupe ) {
      let list = await getDupes();
      let cI = 0;
      let cImg = undefined; 
      if ( cI < list.length ) {
        cImg = list[cI];
      }
      let oimg = cImg === undefined ? undefined : await getBySrc(`/${cImg._id}`);
      this.setState({dupe: true, cI, cImg, imgList: list, oimg});
      return;
    } else {
      this.setState({
        searchQuery: undefined,
        imgList: [],
        cI: 0,
        cImg: undefined,
        dupe: false,
        oimg: false
      });
    }
  }
  async fixO(cImg) {
    if ( this.state.dupe ) {
      assert ( cImg.src, `No Source: ${cImg}` );
      assert ( cImg.type, `No Type: ${cImg}` );
      await fixOriginal(cImg.src, cImg.type);
      let oimg = await getBySrc(`/${this.state.cImg._id}`);
      this.setState({oimg});
    }
  }
  async newList(list, tagset, rand=false) {
    if ( rand || tagset !== this.state.searchQuery) {
      let cI = 0;
      let cImg = undefined;
      if ( cI < list.length ) {
        cImg = await getImg(list[cI]._id);
      }
      this.setState({cI, searchQuery: tagset, cImg, imgList: list});
    }
  }
  async onSubmit ( tagset, any=false ) {
    let string = tagSetToString(tagset);
    let list = undefined;
    if ( tagset.length === 0 ) {
      list = await getAll();
    } else {
      list = await searchTags(tagset, any);
    }
    this.newList(list, string);
  }
  async nextImg() {
    let cI = ( this.state.imgList.length + this.state.cI + 1 ) % this.state.imgList.length;
    let cImg = undefined;
    let oimg = undefined;
    if ( cI < this.state.imgList.length ) {
      if ( this.state.dupe ) {
        cImg = this.state.imgList[cI];
        oimg = await getBySrc(`/${cImg._id}`);
      } else {
        cImg = await getImg(this.state.imgList[cI]._id);
      }
    }
    this.setState({cI,cImg,oimg});
  }
  async prevImg() {
    let cI = ( this.state.imgList.length + this.state.cI - 1 ) % this.state.imgList.length;
    let cImg = undefined;
    let oimg = undefined;
    if ( cI < this.state.imgList.length ) {
      if ( this.state.dupe ) {
        cImg = this.state.imgList[cI];
        oimg = await getBySrc(`/${cImg._id}`);
      } else {
        cImg = await getImg(this.state.imgList[cI]._id);
      }
    }
    this.setState({cI,cImg,oimg});
  }
  onRand(App) {
    return ()=>{
      let imgList = shuffle(App.state.imgList);
      App.newList(imgList, App.state.searchQuery, true);
    }
  }
  async updateTags(tags){
    let _tags = new Set(this.state.cImg.tags);
    tags.split(/\s+/).forEach((tag)=>{
      if ( tag.startsWith('-') ) {
        _tags.delete( tag.substring(1) );
      } else if ( tag !== "" ) {
        _tags.add(tag);
      }
    });
    if ( JSON.stringify([..._tags].sort()) !== JSON.stringify(this.state.cImg.tags.sort()) ) {
      let cImg = await upTags ( this.state.cImg._id, [..._tags] );
      this.setState({cImg});
    }
  }
  render() {
    let inside = undefined;
    if ( this.state.dupe === true && this.state.imgList.length > 0 ) {
      inside = <DupeContainer repl={this.repl} dTag={this.dTag} rAdd={this.rAdd} fixO={this.fixO} tag={this.state.cImg.tags} oimg={this.state.oimg} dupe={`files/${this.state.cImg._id}`} image={`dupes/${this.state.cImg.duplicate}`} />;
    } else {
      inside = <ImageContainer fast={this.state.fast} image={this.state.cImg} updateTags={this.updateTags}/>;
    }
    return (
      <div className="App d-flex flex-column">
        <div className="container-fluid pt-3" style={{ height: '6%' }}>
          <Header toggleFast={this.toggleFast} fast={this.state.fast} onSubmit={this.onSubmit} next={this.nextImg} prev={this.prevImg} onRand={this.onRand(this)} dupe={this.dupe} isdupe={this.state.dupe} />
        </div>
        {inside}
      </div>
    );
  }
}

export default App;
