import React, { useState, useEffect } from 'react';

import LoginForm from './LoginForm';
import { checkAuth, login } from '../utils/localAPI';

function AuthContainer({ children }) {
    const [loggedIn, setLoggedIn] = useState(null);
    const [err, setErr] = useState(null);

    useEffect(()=>{
        checkAuth()
            .then(()=>setLoggedIn(true))
            .catch(()=>setLoggedIn(false));
    }, []);

    function submitLogin(username, password) {
        login(username, password)
            .then(data=>setLoggedIn(true))
            .catch(setErr);
    }

    if (loggedIn) {
        return children;
    } else if (loggedIn === null) {
        return null;
    } else {
        return (
            <LoginForm err={err} onSubmit={submitLogin} />
        );
    }
}

export default AuthContainer;
