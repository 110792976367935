import React, { Component } from 'react';
import Taglist from './Taglist';

class Sidebar extends Component {
    render() {
        return ( 
            <div className="col-md-2 order-md-1 order-2 text-right pr-0">
                <Taglist fast={this.props.fast} id={this.props.id} tags={this.props.tags} updateTags={this.props.updateTags} />
            </div>
        )       
    }   
}

export default Sidebar;